module.exports = {
    code: '',
    filter: {
        args: [
            {
                "value": "output"
            },
        ],
        signature: 'onMouseUp(output)',
        slotKey: "-1",
    },
    key: "0"
}
