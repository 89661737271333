module.exports = {
    code: '',
    filter: {
        args: [
            {
                "value": "",
                "options": [
                    "1",
                    "2",
                    "3",
                    "4",
                    "8",
                    "6",
                    "*",
                ]
            }
        ],
        signature: 'onStatusChanged(status)',
        sigDesc: '--for Industry Units',
        slotKey: "-1",
    },
    key: "0"
}
