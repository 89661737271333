export default {
    getCompletions: function (editor, session, pos, prefix, callback) {

        const constList = [
            "controlMasterModeId",
            "controlMasterModeId.travel",
            "travel",
            "controlMasterModeId.cruise",
            "cruise",

            "axisCommandId",
            "axisCommandId.longitudinal",
            "longitudinal",
            "axisCommandId.lateral",
            "lateral",
            "axisCommandId.vertical",
            "vertical",

            "axisCommandType",
            "axisCommandType.byThrottle",
            "byThrottle",
            "axisCommandType.byTargetSpeed",
            "byTargetSpeed",
            "axisCommandType.unused",
            "unused",
        ];

        const list = {
            "AxisCommand": [
                "setupCustomTargetSpeedRanges(customTargetSpeedRanges)",
                "getTargetSpeedCurrentStep()",
                "computeTargetSpeedStepValue(referenceSpeed, commandStep)",
                "onMasterModeChanged(masterModeId)",
                "resetCommand()",
                "updateCommandFromMouseWheel(mouseWheelInc)",
                "updateCommandFromActionStart(commandStep)",
                "updateCommandFromActionStop(commandStep)",
                "updateCommandFromActionLoop(commandStep)",
                "getCommandValue()",
                "updateCommandByStep(commandStep)",
                "setCommandByThrottle(throttle)",
                "setCommandByTargetSpeed(targetSpeed)",
                "getAccelerationCommandToTargetSpeed(currentAxisSpeedMS)",
                "composeAxisAccelerationFromThrottle(tags)",
            ],
            "AxisCommandManager": [
                "getMasterMode()",
                "getAxisCommandType(commandAxis)",
                "setMasterMode(masterModeId)",
                "updateCommandFromMouseWheel(commandAxis, mouseWheelInc)",
                "resetCommand(commandAxis)",
                "updateCommandFromActionStart(commandAxis, commandStep)",
                "updateCommandFromActionStop(commandAxis, commandStep)",
                "updateCommandFromActionLoop(commandAxis, commandStep)",
                "getThrottleCommand(commandAxis)",
                "getTargetSpeed(commandAxis)",
                "setupCustomTargetSpeedRanges(commandAxis, customTargetSpeedRanges)",
                "getTargetSpeedCurrentStep(commandAxis)",
                "activateGroundEngineAltitudeStabilization()",
                "deactivateGroundEngineAltitudeStabilization()",
                "setTargetGroundAltitude(targetAltitude)",
                "updateTargetGroundAltitudeFromActionStart(altitudeStabilizationInc)",
                "updateTargetGroundAltitudeFromActionLoop(altitudeStabilizationInc)",
                "updateGroundEngineAltitudeStabilization()",
            ]
        };


        let autocomplete = [];
        Object.entries(list).forEach(([li, l]) => {
            autocomplete.push({
                caption: li,
                value: l,
                meta: "keyword"
            });
            l.forEach(fn => {
                autocomplete.push({
                    caption: `${li}:${fn}`,
                    value: `${li}:${fn}`,
                    meta: "function"
                });
                autocomplete.push({
                    caption: fn,
                    value: fn,
                    meta: "function"
                });
            })
        });

        callback(null, [
                ...autocomplete,
                ...constList.map(function (word) {
                    return {
                        caption: word,
                        value: word,
                        meta: "constant"
                    };
                })
            ]
        );
    }
}
