<template>
  <header class="app-header">
    <div class="brand">{{ $route.meta.title.toUpperCase() }}</div>
    <template v-if="$route.name !== 'home'">
      <button class="btn btn-dark" @click="$router.push('/')">
        <font-awesome-icon icon="home"></font-awesome-icon>
        Home
      </button>
      <button v-if="$route.name !== 'editor'" class="btn btn-dark" @click="$router.push('/editor')">
        <font-awesome-icon icon="code"></font-awesome-icon>
        Editor
      </button>
      <button v-if="$route.name !== 'utils'" class="btn btn-dark" @click="$router.push('/utils')">
        <font-awesome-icon icon="code"></font-awesome-icon>
        Utilities
      </button>
      <button v-if="$route.name !== 'items'" class="btn btn-dark" @click="$router.push('/items')">
        <font-awesome-icon icon="book"></font-awesome-icon>
        Item API Dump
      </button>
      <button v-if="$route.name !== 'recipes'" class="btn btn-dark" @click="$router.push('/recipes')">
        <font-awesome-icon icon="book"></font-awesome-icon>
        Recipes API Dump
      </button>
      <button v-if="$route.name !== 'codex'" class="btn btn-dark" @click="$router.push('/codex')">
        <font-awesome-icon icon="book"></font-awesome-icon>
        Codex
      </button>
      <button v-if="$route.name !== 'emulator'" class="btn btn-dark" @click="$router.push('/emulator')">
        <font-awesome-icon icon="play"></font-awesome-icon>
        Emulator
      </button>
      <button v-if="$route.name !== 'discord'" class="btn btn-dark" @click="$router.push('/discord')">
        <font-awesome-icon :icon="['fab', 'discord']"></font-awesome-icon>
        Discord Bot
      </button>
      <div class="menu">
      </div>
    </template>
    <!--    <h4>{{ $route.meta.title.toUpperCase() }}</h4>-->
  </header>
</template>

<script>
export default {
  name: "Header",
}
</script>

<style scoped>
header {
  border-bottom: 3px solid rgba(0, 0, 0, .25);
  /*  background-color: rgba(29, 40, 53, .95);*/
}
</style>
