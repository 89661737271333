module.exports = {
    code: '',
    filter: {
        args: [
            {
                "value": "stress"
            },
        ],
        signature: 'onStressChanged(stress)',
        slotKey: "-1",
    },
    key: "0"
}
