import duLuaEditor from "@/components/duLuaEditor";
import Home from "@/components/Home";
import luaEmulator from "@/components/luaEmulator";
import luaCodex from "@/components/luaCodex";
import DiscordBot from "@/components/DiscordBot";
import DUItemList from "@/components/DUItemList";
import DURecipeList from "@/components/DURecipeList";
import DUCraftCalculator from "@/components/DUCraftCalculator";
import UnsupportedPage from "@/components/UnsupportedPage";
import UtilitiesComponent from "@/components/utilities/UtilitiesComponent";

export default [
    {path: '/', name: 'home', component: Home, meta: {title: 'Lua tools for Dual Universe'}},
    {
        path: '/editor/', name: 'editor', component: duLuaEditor, meta: {title: 'Online Lua editor for Dual Universe'},
        children: [
            {path: 'github/:user/:repo', name: 'github_editor', meta: {title: 'Online Lua editor for Dual Universe'}},
        ]
    },
    {path: '/editor/*', name: 'unsupported_editor_stuff', component: UnsupportedPage, meta: {title: 'Not Supported'}},
    {path: '/emulator', name: 'emulator', component: luaEmulator, meta: {title: 'Online Lua emulator'}},
    {path: '/utils', name: 'utils', component: UtilitiesComponent, meta: {title: 'Utilities'}},
    {path: '/codex', name: 'codex', component: luaCodex, meta: {title: 'Dual Universe Lua API Codex'}},
    {path: '/discord', name: 'discord', component: DiscordBot, meta: {title: 'Lua bot for Discord'}},
    {path: '/items', name: 'items', component: DUItemList, meta: {title: 'DU Items API Dump'}},
    {path: '/recipes', name: 'recipes', component: DURecipeList, meta: {title: 'DU Recipes API Dump'}},
    {path: '/craftcalculator', name: 'craftcalculator', component: DUCraftCalculator, meta: {title: 'DU Craft Calculator'}},
    {path: '*', name: 'global_unsuported', component: UnsupportedPage, meta: {title: 'Not Supported'}},
]
