module.exports = {
    code: '',
    filter: {
        args: [
            {
                "value": "x"
            },
            {
                "value": "y"
            }
        ],
        signature: 'onMouseUp(x, y)',
        slotKey: "-1",
    },
    key: "0"
}
