export default {
    getCompletions: function (editor, session, pos, prefix, callback) {
        const list = {
            "Nav": [
                "toggleBoosters()",
                "update()",
                "maxForceForward()",
                "maxForceBackward()",
                "isCruiseMode()",
                "isTravelMode()",
                "getTargetGroundAltitude()",
                "setEngineCommand(tags, acceleration, angularAcceleration, keepForceColinearity, keepTorqueColinearity, priority1SubTags, priority2SubTags, priority3SubTags, tolerancePercentToSkipOtherPriorities)",
                "setEngineForceCommand(self, tags, acceleration, keepForceColinearity, priority1SubTags, priority2SubTags, priority3SubTags, tolerancePercentToSkipOtherPriorities)",
                "setEngineTorqueCommand(self, tags, angularAcceleration, keepTorqueColinearity, priority1SubTags, priority2SubTags, priority3SubTags, tolerancePercentToSkipOtherPriorities)",
            ],
            "Nav.axisCommandManager": [
                "setupCustomTargetSpeedRanges(customTargetSpeedRanges)",
                "getTargetSpeedCurrentStep()",
                "computeTargetSpeedStepValue(referenceSpeed, commandStep)",
                "onMasterModeChanged(masterModeId)",
                "resetCommand()",
                "updateCommandFromMouseWheel(mouseWheelInc)",
                "updateCommandFromActionStart(commandStep)",
                "updateCommandFromActionStop(commandStep)",
                "updateCommandFromActionLoop(commandStep)",
                "getCommandValue()",
                "updateCommandByStep(commandStep)",
                "setCommandByThrottle(throttle)",
                "setCommandByTargetSpeed(targetSpeed)",
                "getAccelerationCommandToTargetSpeed(currentAxisSpeedMS)",
                "composeAxisAccelerationFromThrottle(tags)",
                "composeAxisAccelerationFromTargetSpeed(tags)",
                "getMasterMode()",
                "getAxisCommandType(commandAxis)",
                "setMasterMode(masterModeId)",
                "updateCommandFromMouseWheel(commandAxis, mouseWheelInc)",
                "resetCommand(commandAxis)",
                "updateCommandFromActionStart(commandAxis, commandStep)",
                "updateCommandFromActionStop(commandAxis, commandStep)",
                "updateCommandFromActionLoop(commandAxis, commandStep)",
                "getThrottleCommand(commandAxis)",
                "getTargetSpeed(commandAxis)",
                "setupCustomTargetSpeedRanges(commandAxis, customTargetSpeedRanges)",
                "getTargetSpeedCurrentStep(commandAxis)",
                "activateGroundEngineAltitudeStabilization()",
                "deactivateGroundEngineAltitudeStabilization()",
                "setTargetGroundAltitude(targetAltitude)",
                "updateTargetGroundAltitudeFromActionStart(altitudeStabilizationInc)",
                "updateTargetGroundAltitudeFromActionLoop(altitudeStabilizationInc)",
                "updateGroundEngineAltitudeStabilization()",
            ],
        };

        let autocomplete = [];
        Object.entries(list).forEach(([li, l]) => {
            autocomplete.push({
                caption: li,
                value: l,
                meta: "keyword"
            });
            l.forEach(fn => {
                autocomplete.push({
                    caption: `${li}:${fn}`,
                    value: `${li}:${fn}`,
                    meta: "function"
                });
                autocomplete.push({
                    caption: fn,
                    value: fn,
                    meta: "function"
                });
            })
        });

        callback(null, autocomplete);
    }
}
