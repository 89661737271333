<template>
  <tr>
    <td colspan="10">
      <div class="row">
        <div class="col">
          <button :disabled="$parent.page <= 1" class="btn btn-primary me-1" @click="$parent.page=1">
            &lt;&lt; Page 1
          </button>
          <button :disabled="$parent.page <= 1" class="btn btn-primary" @click="$parent.page--">
            &lt; Prev. Page
          </button>
        </div>
        <div class="col text-center">
          Page <input v-model="$parent.page" class="form-control form-control-sm" placeholder="page" style="width: 50px;display: inline;" type="text"> /
          {{ Math.ceil($parent.filterItems().length / $parent.byPage) }} with <input v-model="$parent.byPage" class="form-control form-control-sm text-center" style="width: 50px;display: inline;" type="text"> elements by page
          <br>
          <small>from {{ ($parent.page - 1) * $parent.byPage + 1 }} to {{ $parent.page * $parent.byPage }} of {{ $parent.filterItems().length }}</small>
        </div>
        <div class="col text-end">
          <button :disabled="$parent.page >= Math.ceil($parent.filterItems().length/$parent.byPage)" class="btn btn-primary me-1" @click="$parent.page++">
            Next Page &gt;
          </button>
          <button :disabled="$parent.page >= Math.ceil($parent.filterItems().length/$parent.byPage)" class="btn btn-primary" @click="$parent.page=Math.ceil($parent.filterItems().length/$parent.byPage)">
            Page {{ Math.ceil($parent.filterItems().length / $parent.byPage) }} &gt;&gt;
          </button>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "DUItemListPagination"
}
</script>

<style scoped>

</style>
