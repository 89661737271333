<template>
  <table class="table table-sm table-striped table-hover">
    <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(arg, ai) in args" :key="key_base + '_arg_' + ai">
      <td>{{ arg.name }}</td>
      <td>{{ arg.type }}</td>
      <td>{{ arg.description }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "luaCodexMethodArgsTable",
  props: {
    key_base: String,
    args: {}
  }
}
</script>

<style scoped>

</style>
