import start from "@/editor/filters/onStart";
import stop from "@/editor/filters/onStop";
//system
import inputText from '@/editor/filters/system/onInputText';
import flush from '@/editor/filters/system/onFlush';
import update from '@/editor/filters/system/onUpdate';
import actionStart from '@/editor/filters/system/onActionStart';
import actionStop from '@/editor/filters/system/onActionStop';
import actionLoop from '@/editor/filters/system/onActionLoop';
//player
import onParentChanged from '@/editor/filters/player/onParentChanged';
//construct
import onConstructDocked from "@/editor/filters/construct/onConstructDocked";
import onDocked from "@/editor/filters/construct/onDocked";
import onUnDocked from "@/editor/filters/construct/onUnDocked";
import onPvPTimer from "@/editor/filters/construct/onPvPTimer";
import onVRStationEntered from "@/editor/filters/construct/onVRStationEntered";
//unit
import onTimer from '@/editor/filters/unit/onTimer';
//radar or detectionZone
import enter from "@/editor/filters/radar/onEnter";
import leave from "@/editor/filters/radar/onLeave";
import identified from "@/editor/filters/radar/onIdentified";
//container
import storageAcquired from '@/editor/filters/container/onContentUpdate';
//industry
import onStarted from '@/editor/filters/industry/onStarted';
import completed from '@/editor/filters/industry/onCompleted';
import statusChanged from '@/editor/filters/industry/onStatusChanged';
//mining units
import MUonCalibrated from '@/editor/filters/miningUnits/onCalibrated';
import MUonCompletted from '@/editor/filters/miningUnits/onCompleted';
import MUonStarted from '@/editor/filters/miningUnits/onStarted';
import MUonStopped from '@/editor/filters/miningUnits/onStopped';
import MUonStateChanged from '@/editor/filters/miningUnits/onStateChanged';
//plasma extractors
import PEonCompletted from '@/editor/filters/plasma/onCompleted';
import PEonStarted from '@/editor/filters/plasma/onStarted';
import PEonStopped from '@/editor/filters/plasma/onStopped';
import PEonStateChanged from '@/editor/filters/plasma/onStateChanged';
//laser detector
import laserHit from '@/editor/filters/laserDetector/onHit';
import laserRelease from '@/editor/filters/laserDetector/onLoss';
//switch or button
import pressed from '@/editor/filters/button/onPressed';
import released from '@/editor/filters/button/onReleased';
//receiver
import receive from '@/editor/filters/receiver/onReceived';
//shield
import absorbed from '@/editor/filters/shield/onAbsorbed';
import down from '@/editor/filters/shield/onDown';
import restored from '@/editor/filters/shield/onRestored';
import venting from '@/editor/filters/shield/onVenting';
//transponder & shield
import toggled from '@/editor/filters/onToggled'
//screen
import mouseup from '@/editor/filters/screen/onMouseUp';
import mousedown from '@/editor/filters/screen/onMouseDown';
import onOutputChanged from '@/editor/filters/screen/onOutputChanged';
//core
import stressChanged from '@/editor/filters/core/onStressChanged';

let library_slot = [];
library_slot.push(start);
library_slot.push(stop);

let system_slot = [];
system_slot.push(start);
system_slot.push(stop);
system_slot.push(inputText);
system_slot.push(flush);
system_slot.push(update);
system_slot.push(actionStart);
system_slot.push(actionStop);
system_slot.push(actionLoop);

let player_slot = [];
player_slot.push(start);
player_slot.push(stop);
player_slot.push(onParentChanged);

let construct_slot = [];
construct_slot.push(start);
construct_slot.push(stop);
construct_slot.push(onConstructDocked);
construct_slot.push(onDocked);
construct_slot.push(onUnDocked);
construct_slot.push(onPvPTimer);
construct_slot.push(onVRStationEntered);

let unit_slot = [];
unit_slot.push(start);
unit_slot.push(stop);
unit_slot.push(onTimer);

let device_slot = [];
device_slot.push(start);
device_slot.push(stop);
device_slot.push(storageAcquired);
device_slot.push(enter);
device_slot.push(leave);
device_slot.push(identified);
device_slot.push(onStarted);
device_slot.push(completed);
device_slot.push(statusChanged);
device_slot.push(MUonCalibrated);
device_slot.push(MUonStarted);
device_slot.push(MUonCompletted);
device_slot.push(MUonStopped);
device_slot.push(MUonStateChanged);
device_slot.push(PEonStarted);
device_slot.push(PEonCompletted);
device_slot.push(PEonStopped);
device_slot.push(PEonStateChanged);
device_slot.push(laserHit);
device_slot.push(laserRelease);
device_slot.push(pressed);
device_slot.push(released);
device_slot.push(receive);
device_slot.push(absorbed);
device_slot.push(venting);
device_slot.push(down);
device_slot.push(restored);
device_slot.push(toggled);
device_slot.push(mouseup);
device_slot.push(mousedown);
device_slot.push(onOutputChanged);
device_slot.push(stressChanged);

let radar_slot = [];
radar_slot.push(start);
radar_slot.push(stop);
radar_slot.push(enter);
radar_slot.push(leave);
radar_slot.push(identified);

let weapon_slot = [];
weapon_slot.push(start);
weapon_slot.push(stop);

library_slot = library_slot.sort((a, b) => (a.filter.signature > b.filter.signature ? 1 : -1));
system_slot = system_slot.sort((a, b) => (a.filter.signature > b.filter.signature ? 1 : -1));
player_slot = player_slot.sort((a, b) => (a.filter.signature > b.filter.signature ? 1 : -1));
construct_slot = construct_slot.sort((a, b) => (a.filter.signature > b.filter.signature ? 1 : -1));
unit_slot = unit_slot.sort((a, b) => (a.filter.signature > b.filter.signature ? 1 : -1));
device_slot = device_slot.sort((a, b) => (a.filter.signature > b.filter.signature ? 1 : -1));
radar_slot = radar_slot.sort((a, b) => (a.filter.signature > b.filter.signature ? 1 : -1));
weapon_slot = weapon_slot.sort((a, b) => (a.filter.signature > b.filter.signature ? 1 : -1));

let slots = {
    "-5": library_slot,
    "-4": system_slot,
    "-3": player_slot,
    "-2": construct_slot,
    "-1": unit_slot,
    "0": device_slot,
    "1": device_slot,
    "2": device_slot,
    "3": device_slot,
    "4": device_slot,
    "5": device_slot,
    "6": device_slot,
    "7": device_slot,
    "8": device_slot,
    "9": device_slot,
    "10": radar_slot,
    "11": weapon_slot,
    "12": weapon_slot,
    "13": weapon_slot,
    "14": weapon_slot,
    "15": weapon_slot,
    "16": weapon_slot,
    "17": weapon_slot,
    "18": weapon_slot,
    "19": weapon_slot,
    "20": weapon_slot,
};

export default slots;
