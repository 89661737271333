<template>
  <div class="container-fluid mt-3">
    <div class="container">
      <div class="row">
        <div class="col">
          <label class="fw-bold text-white" for="searchable">Search product name</label>
          <div class="input-group">
            <input id="searchable" v-model="searchText" class="form-control" placeholder="Search Item Name" type="text">
            <div class="input-group-append">
              <div class="dropdown">
                <button class="btn btn-success dropdown-toggle" @click="dropDownToggle = !dropDownToggle">
                  <font-awesome-icon icon="download"/>
                  Download
                </button>
                <ul :class="dropDownToggle ? 'dropdown-menu show' : 'dropdown-menu'">
                  <li><a class="dropdown-item" @click="download('lua')">Lua Table</a></li>
                  <li><a class="dropdown-item" @click="download('json')">JSON</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          &nbsp;
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <button v-for="(c,i) in columns" :key="'btn_col_' + i"
                  :class="c ? 'btn btn-success btn-sm me-1 mb-1' : 'btn btn-info btn-sm me-1 mb-1'"
                  @click="columns[i] = !columns[i]">
            <font-awesome-icon v-show="!c" icon="square"/>
            <font-awesome-icon v-show="c" icon="check"/>
            {{ i }}
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <table class="table table-sm table-hover table-striped" style="margin-bottom: 75px;">
          <thead class="text-white">
          <d-u-recipes-list-pagination></d-u-recipes-list-pagination>
          <tr>
            <th v-if="columns.id">id</th>
            <th v-if="columns.tier">tier</th>
            <th v-if="columns.time">time</th>
            <th v-if="columns.nanocraftable">nanocraftable</th>
            <th v-if="columns.ingredients">ingredients</th>
            <th v-if="columns.products">products</th>
            <th v-if="columns.producers">producers</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(i,ii) in paginate(filterItems())" :key="i.id">
            <td v-if="columns.id">
              <span class="btn btn-link p-0 m-0" title="Copy ID To Clipboard">
                <font-awesome-icon icon="copy" @click="copyToClipboard(i.id, 'ID')"/>
              </span>
              {{ i.id }}
            </td>
            <td v-if="columns.tier">{{ i.tier }}</td>
            <td v-if="columns.time" v-html="secondsToTimeString(i.time)"></td>
            <td v-if="columns.nanocraftable">
              <font-awesome-icon v-show="i.nanocraftable" class="text-success" icon="check-circle"></font-awesome-icon>
              <font-awesome-icon v-show="!i.nanocraftable" class="text-danger" icon="times-circle"></font-awesome-icon>
            </td>
            <td v-if="columns.ingredients">
              <div v-for="(p,pi) in i.ingredients" :key="ii + '_ingredients_' + pi"
                   :class="!p.displayNameWithSize || p.displayNameWithSize.length === 0 ? 'text-danger' : ''">
                {{ numberWithSpaces(p.quantity) }} x {{ p.displayNameWithSize || "UNKNOWN ITEM ID" }} <span v-if="p.locDisplayNameWithSize"> // {{ p.locDisplayNameWithSize }}</span> <span v-if="p.locDisplayNameWithSizeDE"> // {{ p.locDisplayNameWithSizeDE }}</span> <i>({{ p.id }})</i>
              </div>
            </td>
            <td v-if="columns.products">
              <div v-for="(p,pi) in i.products" :key="ii + '_products_' + pi"
                   :class="!p.displayNameWithSize || p.displayNameWithSize.length === 0 ? 'text-danger' : ''">
                {{ numberWithSpaces(p.quantity) }} x {{ p.displayNameWithSize || "UNKNOWN ITEM ID" }} <span v-if="p.locDisplayNameWithSize"> // {{ p.locDisplayNameWithSize }}</span> <span v-if="p.locDisplayNameWithSizeDE"> // {{ p.locDisplayNameWithSizeDE }}</span> <i>({{ p.id }})</i>
              </div>
            </td>
            <td v-if="columns.producers">
              <div v-for="(p,pi) in i.producers" :key="ii + '_products_' + pi"
                   :class="!p.displayNameWithSize || p.displayNameWithSize.length === 0 ? 'text-danger' : ''">
                {{ p.displayNameWithSize || "UNKNOWN ITEM ID" }} <span v-if="p.locDisplayNameWithSize"> // {{ p.locDisplayNameWithSize }}</span> <span v-if="p.locDisplayNameWithSizeDE"> // {{ p.locDisplayNameWithSizeDE }}</span> <i>({{ p.id }})</i>
              </div>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <d-u-recipes-list-pagination></d-u-recipes-list-pagination>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import * as toastr from 'toastr';
import DURecipesListPagination from "@/components/DURecipesListPagination";

const recipes_data = require('../data/recipes.json');

export default {
  name: "DUItemList",
  components: {DURecipesListPagination},
  data() {
    return {
      recipes: [],
      searchText: '',
      loading: true,
      page: 1,
      byPage: 25,
      columns: {
        id: true,
        tier: true,
        time: true,
        nanocraftable: true,
        ingredients: true,
        products: true,
        producers: true,
      },
      dropDownToggle: false,
    };
  },
  methods: {
    paginate(recipes) {
      if (this.page > 0) {
        const max_page = Math.ceil(this.filterItems().length / this.byPage)
        if (max_page > 0 && this.page > max_page) {
          this.page = max_page;
        }
        return recipes.slice((this.page - 1) * this.byPage, this.page * this.byPage + 1);
      }
      return [];
    },
    filterItems() {
      const searchable = this.searchText.toLowerCase().trim();
      if (searchable && searchable.length > 3) {
        return this.recipes.filter(r => {
          for (let i = 0; i < r.products.length; i++) {
            if (r.products[i].displayNameWithSize && r.products[i].displayNameWithSize.toLowerCase().includes(searchable)) {
              return true;
            } else if (r.products[i].locDisplayNameWithSize && r.products[i].locDisplayNameWithSize.toLowerCase().includes(searchable)) {
              return true;
            } else if (r.products[i].locDisplayNameWithSizeDE && r.products[i].locDisplayNameWithSizeDE.toLowerCase().includes(searchable)) {
              return true;
            } else if (r.products[i].id && r.products[i].id.toString().toLowerCase().includes(searchable)) {
              return true;
            }
          }
          if(r.id && r.id.toString().toLowerCase().includes(searchable)) {
            return true;
          }
          return false;
        });
      }
      return this.recipes;
    },
    copyToClipboard(data, name = 'ID') {
      navigator.clipboard.writeText(data).then(() => {
        toastr.success(name + " copied");
      }).catch(() => {
        toastr.danger('Failed to copy ' + name);
      })
    },
    numberWithSpaces(x) {
      let parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    },
    download(mode = "lua") {
      const recipes = this.filterItems();
      let items_to_download = []
      recipes.forEach(recipe => {
        let i = {
          id: recipe.id
        }
        if (this.columns.tier) i.tier = recipe.tier;
        if (this.columns.time) i.time = recipe.time;
        if (this.columns.nanocraftable) i.nanocraftable = recipe.nanocraftable;
        if (this.columns.ingredients) i.ingredients = recipe.ingredients;
        if (this.columns.products) i.products = recipe.products;
        if (this.columns.producers) i.producers = recipe.producers;
        items_to_download.push(i);
      });

      let content = ""
      if (mode === 'json') {
        content = JSON.stringify(items_to_download);
      } else if (mode === 'lua') {
        let first = true;
        content = '_recipes={'
        items_to_download.forEach(item => {
          if (!first) {
            content += ',';
          }
          content += '["' + item.id + '"]={'
          let first_key = true;
          Object.keys(item).forEach(k => {
            if (!first_key) content += ',';
            if (typeof item[k] === 'object') {
              content += k + '={';
              let first_key_2 = true;
              item[k].forEach(i => {
                let first_key_3 = true;
                if (!first_key_2) content += ',';
                content += '{';
                Object.keys(i).forEach(k2 => {
                  if (!first_key_3) content += ',';
                  content += k2 + '=' + JSON.stringify(i[k2]);
                  first_key_3 = false;
                });
                content += '}';
                first_key_2 = false;
              });
              content += '}'
            } else {
              content += k + '=' + JSON.stringify(item[k]).replaceAll('[', '{').replaceAll(']', '}').replaceAll(':', '=');
            }
            if (first_key) first_key = false;
          });
          content += '}';
          if (first) {
            first = false;
          }
        });
        content += '}'
      }

      const filename = "recipes_api_dump." + mode;
      const file = new File([content], filename, {type: 'text/plain'});
      const elem = window.document.createElement('a');
      const url = window.URL.createObjectURL(file);
      elem.href = url;
      elem.download = file.name;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
      window.URL.revokeObjectURL(url)
    },
    secondsToTimeString(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds - hours * 3600) / 60);
      const seconds_ = seconds - hours * 3600 - minutes * 60;
      return hours + "h " + minutes + "m " + seconds_ + "s" + ' <i>(' + this.numberWithSpaces(seconds) + 's)</i>';
    }
  },
  mounted() {
    this.recipes = recipes_data;
    this.loading = false;
  }
}
</script>

<style scoped>
</style>
