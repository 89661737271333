<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col text-center">
        <img class="img-thumbnail rounded-circle" src="https://cdn.discordapp.com/app-icons/978639158451007548/a745ff9fd261fd174b00c7d87e0b1b97.webp"/>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <h3>A Discord Bot that can run and minify LUA code</h3>
      </div>
    </div>
    <div class="row">
      <div class="col">&nbsp;</div>
    </div>
    <div class="row">
      <div class="col text-center">
        <a class="btn btn-lg btn-info" href="/discord-invite" target="_blank">
          <font-awesome-icon :icon="['fab', 'discord']"></font-awesome-icon>
          Invite the bot
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col">&nbsp;</div>
    </div>
    <div class="row">
      <div class="col">
        <b-tabs content-class="mt-3">
          <b-tab v-for="(category, cat_id) in commands" :key="'category_' + cat_id" :active="cat_id === 0" :title="category.name">
            <div class="row">
              <div class="col">
                <h4>{{category.name}}</h4>
              </div>
            </div>
            <div class="row">
              <div class="col">&nbsp;</div>
            </div>
            <div class="row">
              <div class="col">
                <div v-for="(command, ci) in category.commands" :key="'command_' + ci" class="card mb-3 text-white">
                  <div class="card-header text-white">
                    <b v-html="command.name"></b>
                  </div>
                  <div class="card-body">
                    <i v-html="command.description"></i>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="LUA Libraries Available">
            <div class="row">
              <div class="col">
                <h4>Libraries available in the bot</h4>
                <small>
                  <i>
                    <code>require()</code> is not supported for many reason but some library are already included in the bot, feel free to contact and ask if you need more.
                  </i>
                </small>
              </div>
            </div>
            <div class="row">
              <div class="col">&nbsp;</div>
            </div>
            <div class="row">
              <div class="col">
                <div class="card mb-3 text-white">
                  <div class="card-header text-white">
                    <b>DKJSON</b>
                  </div>
                  <div class="card-body">
                    <i>
                      DKJSON is loaded by default as <code>json</code>.<br>
                      You can use <code>json.decode()</code> or <code>json.encode()</code> in your scripts.
                    </i>
                  </div>
                </div>

                <div class="card mb-3 text-white">
                  <div class="card-header text-white">
                    <b>Dual Univers Atlas</b>
                  </div>
                  <div class="card-body">
                    <i>
                      The dual universe atlas is loaded by default as <code>atlas</code>.
                    </i>
                  </div>
                </div>

                <div class="card mb-3 text-white">
                  <div class="card-header text-white">
                    <b>Dual universe utilities library</b>
                  </div>
                  <div class="card-body">
                    <i>
                      The Dual Universe utilities library is loaded by default as <code>utils</code>.<br>
                      You can use all the functions usually found in it like <code>utils.clamp()</code> or <code>utils.round()</code> in your scripts.
                    </i>
                  </div>
                </div>

                <div class="card mb-3 text-white">
                  <div class="card-header text-white">
                    <b>Dual Universe Standard Functions</b>
                  </div>
                  <div class="card-body">
                    <i>
                      The standard function from dual universe <code>system.print()</code> is supported and you can use it in your scripts.
                    </i>
                  </div>
                </div>

              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col">&nbsp;</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DiscordBot",
  data() {
    return {
      selected_category_index: 0,
      commands: [
        {
          name: "Generic Commands",
          commands: [
            {
              name: "/help",
              description: "Show the help message.",
            },
            {
              name: '/du-server-status',
              description: 'Show the status of the servers.',
            },
            {
              name: '/invite',
              description: 'Get the invite link for the bot.',
            }
          ]
        },
        {
          name: 'Game Utility Commands',
          commands: [
            {
              name: '/suspeed <code>distance_in_su</code> <code>duration_in_h</code>',
              description: 'Give the speed to required to fly <code>distance_in_su</code> SU in <code>duration_in_h</code> hours.<br>' +
                  '- <code>distance_in_su</code> The distance must be between 1 and 10000 SU.<br>' +
                  '- <code>duration_in_h</code> The duration must be between 1 and 24 hours. This parameter is optionnal and 8 will be used if not specified.',
            },
            {
              name: '/sutime <code>distance_in_su</code> <code>speed_in_km_h</code>',
              description: 'Give the time to required to fly <code>distance_in_su</code> SU at <code>speed_in_km_h</code> km/h.<br>' +
                  '- <code>distance_in_su</code> The distance must be between 1 and 10000 SU.<br>' +
                  '- <code>speed_in_km_h</code> The speed must be between 1 and 50000 km/h. This parameter is optionnal and 50000 will be used if not specified.',
            },
            {
              name: '/warpcells <code>distance_in_su</code> <code>total_ship_mass</code>',
              description: 'Give the amount of warp cells required for a warp.<br>' +
                  '- <code>distance_in_su</code> The warp distance, must be between 1 and 500.<br>' +
                  '- <code>total_ship_mass</code> The total mass of the ship in tons, must be between 1 and 1000000.',
            },
          ]
        },
        {
          name: "Lua Commands",
          commands: [
            {
              name: ".lua-run <code>script</code>",
              description: 'run <code>script</code>'
            },
            {
              name: "/lua_run_file <code>script</code>",
              description: 'run the lua code in the file <code>script</code>'
            },
            {
              name: ".lua-minify <code>script</code>",
              description: 'minify <code>script</code>'
            },
            {
              name: "/lua_minify_file <code>script</code>",
              description: 'minify the lua code in the file <code>script</code>'
            },
          ]
        },
      ],
    };
  }
}
</script>

<style scoped>
.card {
  background-color: rgba(0, 0, 0, .25);
}
</style>
