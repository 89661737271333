<template>
  <div>
    <div class="row">
      <div class="col">
        <h4>Color Converter</h4>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="colorPicker">Color Picker</label>
          <input id="colorPicker" v-model="color" class="form-control" style="padding: 0;" type="color" @change="updateColor()">
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <div class="form-group">
          <label for="colorPickerHex">Hexadecimal</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <button v-b-tooltip class="btn btn-success" title="Copy" type="button" @click="copyToClipboard(color)">
                <font-awesome-icon icon="copy"></font-awesome-icon>
              </button>
            </div>
            <input id="colorPickerHex" v-model="color" class="form-control" type="text" @change="updateColor()">
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="colorPickerRGB">RGB</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <button v-b-tooltip class="btn btn-success" title="Copy" type="button" @click="copyToClipboard(rgb)">
                <font-awesome-icon icon="copy"></font-awesome-icon>
              </button>
            </div>
            <input id="colorPickerRGB" v-model="rgb" class="form-control" type="text" @change="updateColorFromRgb()">
          </div>

        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="colorPickerPercent">Percent (RenderScript/Light colors)</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <button v-b-tooltip class="btn btn-success" title="Copy" type="button" @click="copyToClipboard(percent)">
                <font-awesome-icon icon="copy"></font-awesome-icon>
              </button>
            </div>
            <input id="colorPickerPercent" v-model="percent" class="form-control" type="text" @change="updateColorFromPercent()">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as toastr from "toastr";

export default {
  name: "ColorPickerConverter",
  data() {
    return {
      color: "#000000",
      rgb: "0,0,0",
      percent: "0,0,0",
    };
  },
  methods: {
    updateColor() {
      this.rgb = this.hexToRgb(this.color);
      this.percent = this.hexToPercent(this.color);
    },
    updateColorFromRgb() {
      this.color = this.rgbToHex(this.rgb);
      this.percent = this.hexToPercent(this.color);
    },
    updateColorFromPercent() {
      this.color = this.percentToHex(this.percent);
      this.rgb = this.hexToRgb(this.color);
    },
    percentToHex(percent) {
      let rgb = percent.split(",");
      let r = Math.round(rgb[0] * 255);
      let g = Math.round(rgb[1] * 255);
      let b = Math.round(rgb[2] * 255);
      return this.rgbToHex(r + "," + g + "," + b);
    },
    getRGB(color) {
      return {
        r: parseInt(color.substring(1, 3), 16),
        g: parseInt(color.substring(3, 5), 16),
        b: parseInt(color.substring(5, 7), 16),
      };
    },
    rgbToHex(rgb) {
      let rgbArray = rgb.split(",");
      let r = parseInt(rgbArray[0]);
      let g = parseInt(rgbArray[1]);
      let b = parseInt(rgbArray[2]);
      return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    },
    componentToHex(c) {
      let hex = c.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    },
    hexToRgb() {
      let {r, g, b} = this.getRGB(this.color);
      return +r + "," + +g + "," + +b;
    },
    hexToPercent() {
      let {r, g, b} = this.getRGB(this.color);
      return (r / 255) + "," + (g / 255) + "," + (b / 255);
    },
    copyToClipboard(data) {
      navigator.clipboard.writeText(data).then(() => {
        toastr.success("Color copied");
      }).catch(() => {
        toastr.danger('Failed to copy color');
      })
    },
  }
}
</script>

<style scoped>

</style>
